import { graphql } from 'gatsby';
import React from 'react';
import styled from 'styled-components';

const SectionStyles = styled.section`
  padding: var(--padding-large) var(--padding-medium);

  p {
    margin-bottom: var(--padding-medium);
    line-height: 31.2px;
  }
`;

export default function ContactPage({ data }) {
  const settings = data.settings.nodes[0];
  return (
    <SectionStyles>
      <p>
        {settings.address.title}
        <br />
        {settings.address.address}
      </p>
      <p>
        <span>M.</span> {settings.contact.email}
        <br />
        <span>IG.</span> {settings.contact.instagram}
        <br />
        <span>P.</span> {settings.contact.phone}
      </p>
    </SectionStyles>
  );
}

export const query = graphql`
  query {
    settings: allSanitySiteSettings {
      nodes {
        address {
          title
          address
        }
        contact {
          email
          instagram
          phone
        }
      }
    }
  }
`;
